// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.


/*
 * Application global variables.
 */

// Set Font Awesome font path
//$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";

//@import url("https://fonts.googleapis.com/css?family=Bai+Jamjuree:400,600,700|Open+Sans:400,700");
/*
font-family: 'Bai Jamjuree', sans-serif;
font-family: 'Open Sans', sans-serif;
*/

$logopath: '/assets/klartboard.svg';

$font-family-sans-serif: 'Bai Jamjuree', 'Open Sans', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;


// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss

//
// Color system
//

$font-size-tiny: 9px;
$font-size-small: 11px;
$font-size-medium: 12px;

.mobile-theme{
    $font-size-tiny: 9px;
    $font-size-small: 15px;
    $font-size-medium: 16px;
}

$font-weight-bold: 600;
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$card-column-width: 216px;

$border-light-black: rgba(0,0,0,0.125);
$border-light-white: rgba(255,255,255,0.1);
$border-light-white-outline: rgba(255,255,255,0.2);

$kb-red: #aa2a26;
$kb-green: #8da71c;
$kb-yellow: #f3bd45;                    /* Statusfarve */

$header-color: #1a3b46;

$teal: #20c997;
$kb-cyan: #0094a6;
$cyan: $kb-cyan;
$kb-blue-secondary: #005f74;
$darkblue: #0f2d37;
$kb-blue: #1a3b46;
$blue: #0073dd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: $kb-red; /* #8da71c */            /* Statusfarve */
$orange: #fd7e14;
$yellow: $kb-yellow; /* #f3bd45 */
$kb-lime: $kb-green; /* #8da71c */
$green: #28a745;                        /* Statusfarve */

$kb-light-blue: #f9fbfb;
$kb-sidebar-gray: #f7f7f7;
$kb-background-blue: #e9f0f2; /* Streg adskildelse kort */
$kb-gray-300: #eaf0f2;
$kb-gray-500: #d7dcdd; /* d6dcdd */
$kb-gray-800: #c3d7d4; /* d6dcdd */
$kb-dusty: #c3d7d4;

/* Dark theme */
$kb-dark-gray-800: #101416;
$kb-dark-gray-500: #1d2931;
$kb-dark-gray-200: #4c5a67;
$kb-dark-gray-100: #d3d2d5;

@mixin debug {
    border: 1px dashed $indigo;
    position: relative;
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background: opacify($indigo, .6);
        position: absolute;
        opacity: .2;
        z-index: 10;
        pointer-events: none;
    }
}
@mixin ellipsis {
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
}



/* https://css-tricks.com/dark-modes-with-css/ */
/* https://medium.freecodecamp.org/how-to-get-dark-mode-working-with-css-740ad31e22e */
@media (prefers-color-scheme: dark) {
  $kb-blue: $white;
}

$header-height: 66px;
$sidebar-width: 450px;
$zindex-navigation: 1000;
$zindex-editor: 840; /* Modal dialog er 1200 */
$zindex-editor: 1120; /* Modal dialog er 1200 */
$zindex-modal-with-dialog: 1125; /* Modal dialog er 1200 */
$zindex-modal: 1200; /* Modal dialog er 1200 */
$zindex-popover: 1450;
$zindex-backdrop: 1400;
$zindex-dropdowns: 50000;
$zindex-top: 2500;

.dark-secondary {
    color: $white;
    //background-color: $darkblue;
    background-color: $kb-blue-secondary;

    ::ng-deep .action-panel.no-fade.solid *{
        color: $white;
    }
}


.datatable-header,
.phase-container header{
    border-top: 1px solid $border-light-white;
}
.table thead th{
    border-color: $border-light-white;
    border-bottom: 1px solid $border-light-white;
}

.phase-container header:not(:last-child),
.table thead th:not(:last-child){
    border-right: 1px solid $border-light-white;
}

.color-reference{
    /*
    #94e3fe
    #1ec997
    #1094a6
    #2573dd
    #0f2d37
    #6611f1
    #932092
    #e83f8b
    #f1c9ff
    #d6d6d6
    #fed9a8
    #fe7e14
    #7b2a00
    #7a7a7a

     */
}


$theme-colors: (
        primary: $kb-blue,
        secondary: $gray-600,
        success: $green,
        info: $kb-cyan,
        warning: $yellow,
        danger: $red,
        light: $kb-light-blue,
        dark: $kb-blue
);
/* f9fbfb */

$table-dark-bg: $kb-blue;


$tooltip-bg: $darkblue !default;
ngb-tooltip-window {
}




button[disabled] {
  //filter: grayscale(100%);
  //opacity: 0.5;
    opacity: 1;
}


.card .font-size-lg {
  font-size: 14px;
}

.card .font-size-md {
  font-size: 12px;
}

.card .font-size-sm {
  font-size: 10px;
}

.no-caret:after {
  display: none !important;
}


@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/mixins/_breakpoints";

// https://stackoverflow.com/questions/23274338/how-to-keep-wrapped-flex-items-the-same-width-as-the-elements-on-the-previous-ro

@mixin flex-wrap-fix($flex-basis, $max-viewport-width: 2000px) {
  flex-grow: 1;
  flex-basis: $flex-basis;
  max-width: 100%;

  $multiplier: 1;
  $current-width: 0px;

  @while $current-width < $max-viewport-width {
    $current-width: $current-width + $flex-basis;
    $multiplier: $multiplier + 1;

    @media(min-width: $flex-basis * $multiplier) {
      max-width: percentage(calc(1/$multiplier));

    }
  }
}

.flex-item {
  @include flex-wrap-fix(100px);
}

::ng-deep .popover {
    max-width: unset !important;
}
