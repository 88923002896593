/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@use "theme-variables" as *;

// 3rd party libraries
@import "bootstrap/scss/bootstrap";

// https://github.com/scttcper/ngx-emoji-mart#installation
@import '@ctrl/ngx-emoji-mart/picker';
@import "quill/dist/quill.snow.css";


/*
@import "flagpack/dist/flagpack.css";
@import "@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-pro/scss/brands.scss";
@import "@fortawesome/fontawesome-pro/scss/regular.scss";
@import "@fortawesome/fontawesome-pro/scss/light.scss";
@import "@fortawesome/fontawesome-pro/scss/solid.scss";
*/

// Moved to angular.json in v16
//@import "@swimlane/ngx-datatable/index.css";
//@import "@swimlane/ngx-datatable/assets/icons.css";

// Theme customization
@import "theme/theme.scss";
