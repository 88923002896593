@use "theme-variables" as *;

.dark {
    td {

    }

    .ngx-datatable.bootstrap{
        background-color: $darkblue;
    }
    .ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
        //background-color: $kb-blue-secondary;
        //background-color: $darkblue;
        background-color: transparent;
        color: $white;
    }

    .ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell:not(:last-child) {
        border-color: $white;
    }

}

/*
bootstrap table theme
*/


/*
.datatable-body-cell-label {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  flex-direction: column;
  height: 100%;

  > *:not(app-header-spacer) {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}

.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
  overflow: auto !important;
}

.ngx-datatable.scroll-vertical .datatable-body {
  overflow: hidden;
  overflow-y: auto;
  overflow-scrolling: touch;
}
*/


.datatable-header-cell-template-wrap > * > .d-flex{
    //transform: translateY(-2px);
}

.datatable-header-cell[resizeable]{
    padding-right: 1rem!important;
}
.datatable-header-cell-template-wrap{
    justify-content: space-between;
}
.datatable-header-cell {
    display: flex!important;
    align-items: center;
    justify-content: center;
}
.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap{
    display: flex;
    align-items: center;
    width: 100%;
}

.ngx-datatable.bootstrap.disable-resize {
    .datatable-header .datatable-header-cell .resize-handle{
        //display: none!important;
        //cursor: not-allowed!important;
    }
}
.ngx-datatable.bootstrap {
    box-shadow: none;
    font-size: 13px;
    /*overflow-y: visible;*/
    transition: none;

    pre {
        margin: 0;
        font-size: 80%;
    }

    ::selection {
        background: transparent;
    }

    .column-resizeable .dropdown-wrapper{
        width: calc(100% - 16px);
        width: 100%;
    }
    .datatable-header .datatable-header-cell .resize-handle {
        visibility: visible;
        width: 20px;
        padding: 0;
        transform: translateY(-1px);
        /* overflow: visible;*/
        opacity: .5;
        /*transition: opacity .5s ease-out;*/
    }

    .datatable-header .datatable-header-cell:last-child .resize-handle {
        //display: none !important;
    }

    .resize-handle{
        -webkit-user-drag: none;
    }


    .custom-resize-handle:before,
    .resize-handle:before {
        font-family: "Font Awesome 5 Pro";
        font-family: "Font Awesome 6 Pro";
        font-weight: 900;
        content: "\f58e";
        position: absolute;
        transform: translate(8px, 6px);
        font-size: 12px!important;

    }

    .custom-resize-handle {
        width: 20px;
        color: #fff;
        opacity: .5;
        cursor: ew-resize;
        height: 28px;
        right: 2px;
        &:hover{
            opacity: 1;
        }
    }


    //.table th:not(:last-child):after,
    .datatable-header .datatable-header-cell:not(:last-child):after {
        width: 1px;
        height: 100%;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        content: "";
        border-right: 1px solid $border-light-white;
    }
    .datatable-header .datatable-header-cell:not(:last-child) .resize-handle:hover {
        opacity: 1;
    }

    .datatable-header {
        background: $kb-blue;
        color: $white;

        .datatable-header-cell {
            padding: 5px;

            .datatable-header-cell-label {
            }
        }

        .datatable-row-left {
            .datatable-header-cell {
                background: $kb-blue;
            }
        }
    }

    .datatable-body {
        .datatable-body-row {
            border-bottom: 2px solid $kb-blue;
            border-bottom: 1px solid $kb-dusty;
            background-color: $kb-background-blue;

            &.datatable-row-odd .datatable-body-cell{
                background-color: darken($kb-background-blue, 5%);
            }

            &.active {

            }

            .datatable-body-cell {
                text-align: left;
                padding: 5px;
            }

            .datatable-body-cell:not(:last-child) {
                border-right: 2px solid $kb-blue;
                border-right: 1px solid $kb-dusty;
            }
        }
    }

    .datatable-footer {
        background-color: $kb-blue;
        color: $white;

        .page-count {
            line-height: 50px;
            height: 50px;
            padding: 0 1.2rem;
        }

        .datatable-pager {
            margin: 0 10px;

            ul {
                li {
                    margin: 10px 0;

                    &:not(.disabled) {
                        &.active,
                        &:hover {
                            a {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            a {
                height: 22px;
                min-width: 24px;
                line-height: 22px;
                padding: 0;
                border-radius: 3px;
                margin: 0 3px;
                text-align: center;

                text-decoration: none;
                vertical-align: bottom;
                //color: $white;
            }

            a:link {

            }

            .datatable-icon-left,
            .datatable-icon-skip,
            .datatable-icon-right,
            .datatable-icon-prev {
                font-size: 18px;
                line-height: 27px;
                padding: 0 3px;
            }
        }
    }
}


