.app-loading {
  .logo {
    width: 80px;
    height: 80px;

    // this way asset gets processed by webpack

    background: url(./assets/klartboard.svg) center center no-repeat;
    background-size: contain;


    /*
    -webkit-mask:  url(./assets/klartboard.svg) no-repeat 50% 50%;
    mask: url(./assets/klartboard.svg) no-repeat 50% 50%;
    */
    mask-size: contain;
    animation: pulse 1.5s ease-in-out infinite;
    transform-origin: center;

    /*background-color: #1a3b46;*/

  }

}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    transform: scale(.95);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;

  }
}