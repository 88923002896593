@use "theme-variables" as *;

/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */


.hide-if-card-expander-empty {

    &:has(app-card-expander[data-item-count="0"]) {
        display: none;
    }

}

.nav-link {
    color: $kb-blue;
}

.dark-theme .bg-light .nav-link {
    color: $white !important;
}

.card .scroll-x::-webkit-scrollbar {
    display: none !important; /* Safari and Chrome */
}

.result-list {
    padding-top: 0;
    padding-bottom: 0;

    > * {
        padding: .75rem !important;
        border-bottom: 1px solid $border-light-black;
    }

    > *:last-of-type {
        border-bottom: none;
    }
}

app-user-search {
    input {
        border-bottom-right-radius: .25rem !important;
        border-top-right-radius: .25rem !important;
    }

    &:has(.fa-eraser) {
        input {
            border-bottom-right-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }
}

body:not(.mobile-theme) .grid-list-box .cdk-drop-list {
    display: flex;
    flex-wrap: wrap;

    > * {
        width: calc(50%) !important;
        padding: .1rem;
        align-self: start;
        justify-content: start;
        margin: 0 !important;
    }
}

body:not(.mobile-theme) .grid-list-box .card {
    width: unset;
    //display: flex;
    //flex-direction: row;
    //justify-content: space-between;


    > * {
        width: unset !important;
        margin: 0 !important;
        align-content: center;
        align-items: center;

    }
}

body:not(.mobile-theme) .grid-list.full-list .card {
    > .card-details {
        max-width: unset !important;;
        width: -webkit-fill-available;
        flex-grow: 1;
    }

    .d-flex.flex-row w-100.justify-content-start.align-items-center,
    .flex-grow-1.d-flex.list-unstyled {
        justify-items: end;
        flex-direction: row-reverse;
    }

    .fa-toolbox {
        display: block !important;;
    }

    .project-display {
        display: none !important;
    }

    .flex-grow-1.d-flex.list-unstyled.justify-content-end.align-items-center.m-0.flex-wrap,
    .ms-auto {
        display: none !important;
    }
}

body:not(.mobile-theme) .grid-list .card {

    width: unset;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .fa-toolbox {
        display: none;
    }

    > .card-details {
        max-width: 150px;
        justify-self: end;
        min-width: unset;

        > div:first-child {
            //min-width: unset!important;

        }

        .relation {
            margin-right: 1rem;
        }
    }

    > * {
        //width: unset!important;

        margin: 0 !important;
        align-content: center;
        align-items: center;;

        app-color-label {
            width: 16px !important;
            height: 16px !important;
        }

        div.title {
            align-self: center;
        }

        div.title > div.text-capitalize-firstletter {
            height: 1.2rem !important;
        }

        .project-display {
            display: none;
        }
    }

    > .card-details {
        flex-direction: row-reverse;
        flex-wrap: nowrap !important;
        gap: 1rem;

        > div:first-child {
            //min-width: 250px!important;
        }
    }

    ul.phase {
        border-top: none !important;
        display: none !important;
    }

    .card.mini {
        max-width: 230px;
        min-width: 230px;
    }
}

.mobile-theme {


    .flex-wrap-mobile {
        flex-wrap: wrap;
        row-gap: .5rem;

        .input-group-text {
            width: 40px;
            max-width: 40px;
            flex-shrink: 0;
            margin: 0 !important;
            border-right: none !important;
            padding: 0;
            display: flex;
            align-items: center;
            flex-grow: 0;
            justify-content: center;
        }

        > * {
            max-width: unset !important;
        }

        app-user-search {
            margin: 0 !important;

            input {
                border-bottom-right-radius: .25rem !important;
                border-top-right-radius: .25rem !important;
            }
        }
    }

    .card .scroll-x {
        padding: .25rem;
        border-radius: .25rem;
        border: 1px solid $border-light-black;
        margin-bottom: .25rem;
        overflow-scrolling: touch;


        > ul.flex-wrap {
            flex-wrap: nowrap !important;
        }
    }

    .displays-list.actions-list {
        font-size: 16px;

        .nav-item {
            min-height: 40px;
            display: flex;
            align-items: center;
        }
    }

    .list-unstyled.scroll-y {
        margin-bottom: 0;
    }

    .scroll-y:not(app-sidebar .scroll-y) {
        padding-bottom: 48px;
        max-height: calc(100vh - 66px);
    }

    .list-counter {

    }

    //max-width: 480px;
    font-size: 16px;

    .main-wrapper header {
        position: sticky !important;
        top: 0;
        font-size: 16px;
        z-index: $zindex-modal-with-dialog !important;
    }

    footer {
        height: unset;
        align-items: center;
        position: fixed;
        bottom: 0;
        z-index: $zindex-modal-with-dialog !important;
        width: 100vw;

        .fal {
            font-size: 16px;
        }

        .footer-actions a {
            width: 32px;
            height: 32px;
            margin: 8px;
            border-radius: .25rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
        font-size: 16px;
    }

    .navbar-nav.square {
        width: 60px;
    }

    .create-item-dropdown {
        transform: none !important;
    }

    .-ngb-datepicker:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $kb-blue;
    }

    ngb-datepicker {

        transform: scale(95%) !important;
        position: fixed !important;
        left: 0 !important;
        top: 0 !important;
        padding: .5rem;
        width: calc(100%) !important;
        //height: 100%;
        border: none;
        z-index: 50000 !important;
        border-radius: .5rem;
        box-shadow: 0 2pt 0.2rem rgb(0 0 0 / 20%);

        .ngb-dp-week > * {
            font-size: 18px !important;
            width: 34pt !important;
            height: 34pt !important;
            max-width: 34pt !important;
            min-width: 34pt !important;
            transform-origin: center left;
            transform: scale(95%);

            border: 1px solid $border-light-white-outline;
            background-color: $border-light-white;
            border-radius: 0.45rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ngb-dp-month {
            width: 100%;
        }

        .ngb-dp-week {
            display: grid;
            grid-auto-flow: column;
            align-content: center;
            justify-content: center;

            .ngb-dp-day {
                display: flex;
                width: 100%;
                height: 100%;
                align-content: center;
                justify-content: center;

                > * {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

        }

    }

    .result-list,
    .dropdown-scroll-wrapper.scroll-y.rounded {
        border-radius: .5rem !important;
        padding-bottom: 0 !important;
        max-height: 25vh !important;
        overflow: hidden;
        overflow-y: scroll;
        overflow-scrolling: touch;
    }

    .result-list > * {
        padding: .75rem !important;
        border-bottom: 1px solid $border-light-black;
    }

    .result-list > *:last-of-type {
        border-bottom: none;
    }

    .milestone-plan-selector {
        font-size: 1rem;

        app-milestone-plan-select-item {
            padding: .75rem !important;
            border-bottom: 1px solid $border-light-black;
        }

        app-milestone-plan-select-item:last-of-type {
            border-bottom: none;
        }

        .fas,
        .fa,
        .fal {
            font-size: 18px !important;
        }

    }

    .popover,
    .popover-tools {
        transform: none !important;
        top: unset !important;
        bottom: 1rem !important;
        left: 1rem !important;
        height: fit-content;
        width: calc(100% - 2rem) !important;
        box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%), 0 0 100rem rgb(0 0 0 / 20%);
        border: none;
        border-radius: 1rem;

        .popover-arrow {
            display: none;
        }


    }

    .popover-tools:before {
        //content: "";
        //background-color: rgba(0, 0, 0, 0.1);
        //width: 100%;
        //height: 100%;
        //position: fixed;
        //display: block;
        //top: 0;
    }

    .close-button {
        transform: scale(1.5);
    }


    .filter-color-popover,
    .popover-wrapper,
    .dropdown-wrapper.mobile-fullscreen {
        transform: none !important;
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100%;
        z-index: 50000 !important;


        .close-button {
            position: fixed;
            top: 20px;
            right: 16px;
            z-index: 600;
        }

        h5 {
            padding-top: 1rem !important;
            padding-bottom: 1rem !important;
            font-size: 18px;
            height: 66px !important;
            line-height: 33px;


        }

        .dropdown-item {
            font-size: 16px;
            padding: 0.75rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

    }

    .card {
        width: 100%;
    }

    .card .font-size-md,
    .card,
    .card .title {
        font-size: 14px;
    }

    .card .title {
        font-weight: 500;
        line-height: 1rem;
    }

    ngb-datepicker .ngb-dp-week > *,
    .popover-body > ul > li .fas,
    .popover-body > ul > li .fal,
    .popover-body > ul > li .fa,
    .card-header app-color-label,
    .card app-color-label,
    .card .fas,
    .card .fal,
    .card .fa,
    .card-header .fas,
    .card-header .fal {
        font-size: 18px !important;
        width: 38pt !important;
        height: 38pt !important;
        max-width: 38pt !important;
        min-width: 38pt !important;
        transform-origin: center left;
        transform: scale(95%);

        border: 1px solid $border-light-white-outline;
        background-color: $border-light-white;
        border-radius: 0.45rem;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .card .fas,
    .card .fal,
    .card .fa {
        border: 1px solid $kb-background-blue;
        background-color: $kb-light-blue;
    }

    .action-panel.placement-top-right.corner,
    .action-panel.corner {
        position: relative;
        left: unset;
        top: unset;
        right: unset;
        transform: unset !important;
        padding: 0 !important;
        padding-top: .25rem !important;
        padding-bottom: .25rem !important;

        > li > * > div,
        > li > *,
        > li {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        li > * > div > a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            background-color: $kb-blue-secondary;
            border-radius: .5rem;
            min-height: 48px;

            > * {
                font-size: 18px !important;
                color: #fff;
                margin: .25rem;
            }
        }
    }


}

.d-uber-flex {
    display: flex !important;
}

.dropdown-toggle {
    border-radius: .75rem;
}

.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 20%);
    border-radius: .75rem;
}

.mobile-theme .d-none.d-mobile-block {
    display: block !important;
}

.mobile-theme .d-none.d-mobile-flex {
    display: flex !important;
}

.mobile-theme .d-mobile-none {
    display: none !important;
}

.nav-link, .nav-link {
    > .fa-2x {
        opacity: .75;
    }
}

.nav-link:hover, .nav-link:focus {
    color: $kb-blue;

    > span {
        text-decoration: underline;
    }

    > .fa-2x {
        opacity: 1;
    }
}

.badge-circle {
    width: 1.3rem !important;
    height: 1rem;
    border-radius: 1rem;
    text-align: center;
    border: 1px solid currentColor;

    line-height: .85rem;
    border-radius: 1rem;
    font-size: 9px !important;
    padding: 0;
    padding-bottom: 3px;
}


.rounded {
    border-radius: 0.75rem !important;
}

html {
    --lh: 1.4rem;
    line-height: var(--lh);
}

.tab-pane .list-container[data-items="0"] .list-wrapper {
    display: none;
}

.badge.bg-success {
    color: $white !important;
}

.badge {
    transition: unset;
}

.resize-box {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 8;
    background: $border-light-black;
    pointer-events: none;
}

.input-group > .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
}

.fa-grip-horizontal {
    color: $white;
}

.dark-theme {
    .resize-box {
        background: $border-light-white;
    }

    .fa-grip-horizontal {
        color: $white;
    }

    .nav-item:not(.nav-item:first-child):not(.nav-item.active) {
        border-color: rgba(255, 255, 255, .4);

        &:last-of-type {
        }
    }
}

.popover.popover-with-dialog {
    z-index: $zindex-modal-with-dialog !important;
}

.popover-content-wrapper {
    width: 540px !important;
    //min-height: 200px;
    max-height: 50vh;
    padding: .5rem;
    z-index: ($zindex-modal) 110 !important;
}


.datepicker-validation.ng-invalid {
    color: $red;
    border-color: $red !important;

    &:focus {
        box-shadow: 0 0 0 0.25rem rgba(170, 42, 38, 0.25);
    }
}
.nav-link app-validation-warning-list{
    font-size: 10px;
}
li.validation-error{
    background-color: #efbcba;
    color: $red;
    font-size: 75%;

    //background-color: #413a2a;
    //color: #f2ab26;

}
.is-invalid {
    .form-control.ng-invalid,
    quill-editor,
    .form-group input:not(.disable-validation input) {
        //color: $red;
        //border: 1px solid $red !important;
        //background: opacify($red, .2) !important;
    }

    quill-editor {
        border-radius: .35rem;
        padding: 2px;
    }

}


.popover {
    //max-height: calc(100vh - 150px);

    .popover-container,
    .tabs-container {
        max-height: calc(90vh - 100px);
        overflow-y: auto;
    }
}

.popover-body {
    //max-height: calc(100vh - 150px);
}

.popover-body app-department-picker {
    max-width: 300px;
    display: block;


}

.rich-text-wrapper {
    max-height: 200px;
    overflow: hidden;
    text-overflow: ellipsis;

    color: $kb-blue !important;
    background: $white;
    border-radius: .5rem;
    padding: .5rem;

    p {
        word-break: normal;
        white-space: normal;
        margin-bottom: 0;
        text-overflow: ellipsis;
    }
}

/**/
.-ngx-datatable .datatable-body {
    //display: flex!important;
    //align-items: stretch;
    //border: 1px solid green;
    //flex-direction: column;

    > * {
        min-width: 100%;
        display: flex;
        align-items: stretch;
        //border: 1px solid blue;

        > *,
        .datatable-body-row {
            min-width: 100%;
            //border: 1px solid red;
            align-self: stretch;
        }

        ::ng-deep datatable-selection {
            min-height: 100%;
        }

    }
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell,
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell,
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
    //flex-grow: 1;
    //padding: 0!important;
    > * {
        //padding: 5px;
    }
}

datatable-footer {
    z-index: 30;
}

ngx-datatable:has(datatable-footer) {
    datatable-body {
        //border: 1px solid green!important;
        //padding-bottom: 50px;
    }
}

.rich-text-wrapper.expanded {
    max-height: unset;
}


[role="listbox"].dropdown-menu {
    max-width: 510px;
    min-width: 255px;
}

.close-button {
    position: absolute;
    display: flex;
    right: -12px;
    top: -12px;
    background-color: #000;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 24px;
    height: 24px;
    width: 24px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .fa-times {
        transform: translateY(1px);
    }
}

.list-group-item.rounded {
    border-width: 1px !important;
    border-radius: .5rem !important;
    background-color: $white;
    border-color: $border-light-black;
    border: 1px solid $gray-400;
}

.list-group.scroll-y {
    border: 1px solid rgba(0, 0, 0, 0.125);
    max-height: 120px;

    > * {
        border-left: none;
        border-right: none;
    }

    > :first-child {
        border-top: none;
    }

    > :last-child {
        border-bottom: none;
    }
}

.mt-negative-8px {
    margin-top: -8px;
}

* {
    backface-visibility: hidden;
}

.transform-scale-75 {
    transform: scale(.75);
}

.transform-scale-80 {
    transform: scale(.80);
}

.transform-scale-85 {
    transform: scale(.85);
}

ngb-popover-window.bs-popover-bottom-right > .popover-arrow,
ngb-popover-window.bs-popover-top-right > .popover-arrow {
    right: 5px !important;
    display: none !important;
}

::ng-deep .header-icon .nav-link a {
    color: unset;
}


[ng-reflect-translate] {
    /* color: red!important; */
    position: relative;
}

.debug-wrap {
    @include debug;
}

code.error,
pre,
pre.todo,
pre.debug {
    //position: absolute;
    font-family: Monaco, "Lucida Console", monospace;
    max-height: 80vh;
    overflow-y: auto;
    max-width: 100%;
    background-color: #172127;
    color: #e8bf6a;
    border-radius: 8px;
    border-color: #131c21;
    z-index: 20000;
    padding: .5rem;
    font-size: 75%;
}

.popover pre.debug {
    border-radius: 2px;
}

.popover label {
    color: $darkblue;
}

.popover .text-black-50,
.popover .list-group-item .text-black-50,
.popover .list-group-item {
    color: $darkblue;
    border-color: rgba($darkblue, .5);
    background-color: rgba($darkblue, .05);
}

.popover .text-black-50,
.popover .list-group-item .text-black-50 {
    color: rgba($darkblue, .5) !important;
}

.dark-theme {
    .popover .text-black-50,
    .popover .list-group-item .text-black-50,
    .popover .list-group-item {
        color: $white;
        border-color: rgba($white, .5);
        background-color: rgba($white, .05);
    }

    .popover .text-black-50,
    .popover .list-group-item .text-black-50 {
        color: rgba($white, .5) !important;
    }
}

.popover .popover-content-wrapper .text-black-50 {
    background: unset;
}

ngb-typeahead-window {
    min-width: 300px;
}

code.error {
    position: relative;
    display: block;
    margin-top: .5rem;
    margin-bottom: .5rem;
    width: 600px;

}

code.text-small {
    font-size: 60%;
    white-space: pre-wrap;
}

pre.todo {
    color: #e8bf6a;
    background-color: #402626;

    color: #fff;
    background-color: #aa2a26;
    position: relative;
}

.badge-notification {
    position: absolute;
    top: -50%;
    right: -25%;
    font-family: "Bai Jamjuree", "Open Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

}

.tmp[ng-reflect-translate]:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: orange;
    width: 4px;
    height: 4px;
    left: 0;
    top: 0;
    transform: translate(-2px, -2px);
}

.w-fit-content {
    width: fit-content;
}

.badge-info {
    background-color: $blue;
}


mwlResizable {
    box-sizing: border-box; // required for the enableGhostResize option to work
}

.cdk-drop-list:not(.table-container) {
    border-radius: .5rem;
    transition: background-color 0.2s ease-out, padding 0.2s ease-out;
    /*background-color: pink;*/
}


.text-dark-gray {
    color: #1a2529;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    &.dark-backdrop {
        background: #193b46;
        background: #232323;
        opacity: 0.75 !important;
    }
}

.fit-content {
    width: auto;
    width: fit-content;
}

.dropdown-scroll-wrapper {
    max-height: 12rem;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;

}

.btn {
    max-width: 200px;
}

.mobile-theme .table-mode .card {
    width: 100%;
}

.card {
    background-color: $kb-light-blue;
    color: $kb-blue;
    border-radius: 6px;
    border-width: 1px;

    width: 200px;
    max-width: 100%;

    border-color: $kb-gray-800;

    &.card-case {
        width: 100%;
        /*max-width: calc(100% - 16px);*/
        background: transparent;
        border-radius: unset;
        border: unset;
        margin-bottom: .125rem;
    }

    .drag-indicator-disabled,
    .drag-indicator {
        display: none;
    }

    ul {
        min-height: 19px;
    }

    .card-type {
        min-width: 32px;

    }

    .title {
        font-size: $font-size-small;
        max-height: 32px;
        align-self: center;
        overflow: hidden;


        span {
            width: 100%;
            height: 100%;
            @include ellipsis;
        }

        span:after {

        }
    }

    &.mini {
        margin-bottom: 0;
        cursor: pointer !important;
        width: auto;

        .d-mini-none {
            display: none !important;
        }

        app-color-label {
            width: 16px;
            height: 16px;
            font-size: 50%;
        }

        .card-type {
            min-width: unset;
        }

        .card {
            //border: none!important;
            max-width: 120px !important;
            min-width: 60px !important;
            min-height: unset;
            margin: 0 !important;
        }

        .d-flex.flex-row.w-100.justify-content-start.align-items-center {
            min-width: 130px;

            > .flex-wrap {
                flex-wrap: nowrap !important;

            }
        }

    }

}


/* :not(.disable-drop) > .cdk-drop-list-receiving  */
/*
body.dragging :not(.disable-drop) .cdk-drop-list {
  background: $kb-dusty !important;
  padding: .5rem;
}
*/


:focus {
    outline: none;
}

.dragging .cdk-drop-list {
    min-height: 130px;
}

.dragging .cdk-drop-list .card .card-details {
    display: none !important;
}

.border-light {
    border-color: $border-light-white !important;
}

.list-unstyled li.divider {
    border-right: 1px solid $border-light-white !important;
    height: 100%;
}


.cdk-drop-list-dragging:not(.table-container) {
    background: $kb-blue !important;
    background: $kb-dark-gray-200 !important;
    background: $kb-dusty !important;
    border-color: $border-light-white-outline !important;
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
    transition: none;
    flex-wrap: wrap;

    > * {
        border-color: $border-light-white-outline !important;
    }
}

.dark-theme {
    .cdk-drop-list-dragging:not(.table-container) {
        background: $kb-blue !important;
    }
}


.text-disabled,
.cdk-drop-list-disabled {
    opacity: .5;
}


.max-screen,
.ql-editor {
    max-height: 70vh;
    overflow-y: auto;
}

.max-height-screen {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}

.modal-holder {
    pointer-events: none;
}

.modal-header {
    background-color: $kb-blue;
    color: $white;
    align-items: center;
    display: flex;
}

.modal-dialog > .modal-inner-content {
    background-color: transparent;
    box-shadow: 0 2px 48px rgba(0, 0, 0, 0.25);
    border-radius: .5rem;
    overflow: hidden;
    border: none;


    .modal-header {
        padding-top: 1rem;
        padding-bottom: .5rem;
        border: none;


        h4 {
            font-size: 1rem;
            margin: 0;
            padding-left: .5rem;
        }
    }

    .modal-body .container {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.modal-dialog > .modal-inner-content.overflow-visible {
    overflow: visible;
}

.modal-dialog.modal-lg {
    display: flex;
    align-items: flex-end;
    height: 100%;
    padding-bottom: 4rem;
}

.input-group-date {
    max-width: 9rem;

    input.focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

.form-control-text {
    border: none;
    display: inline-block;
    padding: 0;
    background: unset;
    outline: none;
    cursor: pointer;
    max-width: 100%;
    width: unset;
    text-align: right;
    color: $kb-blue;
}

.list-group-item {
    padding: 0.37rem;
}

.sidebar-wrapper {
    overflow-y: auto;
    height: calc(100% - 37px);
    min-width: $card-column-width;
}


.form-control-text:focus {
    outline: none;

}

.datatable-body-cell-label {
    height: 100%;
}


.m-half {
    margin: 0.125rem !important;
}

.w-50px {
    width: 50px;
}

.w-75px {
    width: 75px;
}

.w-100px {
    width: 100px;
}

.w-150px {
    width: 150px;
}

.w-200px {
    width: 200px;
}

.dropup,
.z-index-2000,
.dropdown-menu {
    z-index: $zindex-dropdowns !important;
}


/* Mouseoverfix : popover */
.color-popover,
.popover {
    z-index: ($zindex-popover) !important;
}

.popover.popover-sub-dialog {
    z-index: 1040 !important;
}

.dropdown-menu {
    font-size: 13px;
}

.hover-underline:hover span {
    text-decoration: underline !important;
}

.dropdown-menu.sub-menu {
    z-index: ($zindex-dropdowns) +10 !important;
}

.zindex-top {
    z-index: ($zindex-top) (+100) !important;
}

.dropdown-scroll-wrapper.rounded {
    border-radius: .25rem !important;
}

.tabs-editor.nav-tabs,
.tabs-editor .nav-tabs {
    z-index: $zindex-top !important;
}

.popover:hover {
    //z-index: 999999;
}

.popover.zindex-top {
    z-index: 999999;
    position: absolute;
}

.popover {
    //border-radius: .75rem;

    //border-radius: 4.8px;
    //z-index: 2001;
}

.header-wrap {
    z-index: 20;
}

.popover-sub-header {
    z-index: 10;
}

/* app.component sætter drag */
/*
.cdk-drag-preview {
  width: 200px;
  box-shadow: 0 5px 8px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: green!important;
  > * {
    width: 100%;
    max-width: unset;
  }
}
*/

.cell-dark {
    /*background-color: darken($kb-background-blue, 50%);*/
    background-color: darken($kb-background-blue, 5%);
}


.cell-light.border-right.frozen {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 3px 0 2px rgb(0 0 0 / 5%)
}

.cell-light.border-right:after {
    //box-shadow: 2px 0px 4px rgba(0,0,0,0.8);
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    //right: -8px;
    content: "";
    display: block;
    //background-color: rgba(0,0,0,0.15);

}

.cell-light {
    background-color: $kb-background-blue;

}

.datatable-row-wrapper:nth-child(even) .cell-light {
    background-color: darken($kb-background-blue, 5%) !important;
}

.content-wrapper {
    border-top: 1px solid $white;
}


.cdk-overlay-pane:not(.overlay-ignore-z),
.cdk-global-overlay-wrapper {
    z-index: $zindex-editor+1000 !important;
}

.dropdown-wrapper.shadow-sm{
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2))
    drop-shadow(1px 0px 0px rgba(255, 255, 255, 0.1))
    drop-shadow(-1px 0px 0px rgba(255, 255, 255, 0.1))
    drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.1))
    drop-shadow(0px -1px 0px rgba(255, 255, 255, 0.1));
}
body > .dropdown {
    z-index: $zindex-editor + 500 !important;
    max-width: 100vw;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2));

    > * {
        filter: drop-shadow(1px 0px 0px rgba(255, 255, 255, 0.1))
                drop-shadow(-1px 0px 0px rgba(255, 255, 255, 0.1))
                drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.1))
                drop-shadow(0px -1px 0px rgba(255, 255, 255, 0.1));
    }
}

.overlay-ignore-z {
    height: calc(100% - 2rem);
    width: calc(100% - 2rem);
    overflow-y: auto;
    border-radius: 0.25rem;

}

.pointer-events-none {
    pointer-events: none;
}

.btn-micro {
    transform: scale(.75) translate(0px, -3px);
}

.modal-content.shadow {
    //width: unset;
    align-self: center;
    justify-self: center;
    border: none;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%), 0 0 1px rgb(0 0 0 / 50%) !important;
}

.modal-body {
    width: 100%;
    align-self: center;
    &.note-editor-modal{
        min-height: 40vh;
        max-height: 80vh;

        :has(app-rich-text-editor){
            .container{
                //border: 1px solid red;
            }
        }
    }
}

.tabs-editor.nav-tabs,
.nav-tabs.categories {
    max-width: 100vw;
    overflow-x: auto !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    li {
        width: auto;
        flex-shrink: 0;
    }
}

.modal-header h4 {
    font-size: 1rem;
    margin-bottom: 0;
}

.popover-body h4 {
    font-size: 1rem;
    padding: 0.25rem;
}

.header-icon {
    width: 32px !important;
    height: 32px !important;
    font-size: 1rem !important;
    padding: 0 !important;
    margin: 0 !important;
    //border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;




    &.fa,
    &.fal,
    &.far,
    .fa,
    .fal,
    .far {
        font-size: 1rem !important;
    }

    > * {
        text-align: center;
    }
}

/*
.modal-content {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
  }
}
*/

.modal-backdrop {
    z-index: ($zindex-modal)-1;
}

.modal {
    z-index: $zindex-modal;

    &.show .modal-dialog {
        transition: 0.25s all;
        opacity: 1;
        /*transform: translate(0, 5vh);*/
    }

    &.custom-show .modal-dialog {
        opacity: 1;
        /*transform: translate(0, 0);*/
    }
}


.badge-danger {
    color: $white !important;
}


.popover-menu {
    border-radius: .25rem;
    border: none;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);

    .popover-arrow {
        &:before,
        &:after {
            border: none;
        }
    }

    .popover-body {
        padding: 0;
        margin: 0;

        .list-unstyled {
            border-radius: .25rem;
            overflow: hidden;
            margin: 0;
            padding: 0;

            li:not(:last-of-type) {
                border-bottom: 1px solid $border-light-black;
                border-bottom: 1px solid $kb-gray-800;
            }

            li {
                margin: 0;
            }

            li:after {
                border: none !important;
            }
        }
    }
}

.dark-theme .popover-menu {
    .popover-body li:not(:last-of-type) {
        border-color: $border-light-white;
    }
}

.mat-badge-content {

}

.navbar-dark .navbar-nav .nav-link {
    color: $white;
}

.table th {
    font-weight: normal;
}

body {
    background-color: $kb-background-blue;
    overflow: hidden; /* Måske ? */
}

body.dark-theme {
    background-color: $darkblue;
}


.sub-display {
    /*overflow: hidden;*/
    width: 100%;
    /* height: 200px;*/
    &.sub-display-top {
    }

    &.sub-display-bottom {
    }
}

.badge.rounded-pill {
    color: unset;
}

.badge.rounded-pill.badge-notification.bg-danger {
    color: $white !important;
}

.max-width-unset {
    max-width: unset;
}

.list-unstyled li span::selection {
    background: transparent !important;
}

.tooltip {
    pointer-events: none;
}

body > .tooltip {
    z-index: $zindex-editor+999999;
}

.badge-counter {
    background-color: #314f59;
}

.h-1px {
    height: 1px;
}

a,
a:link {
    cursor: pointer;
    pointer-events: all;
}

.buttons {
    .fa,
    .fal {
        font-size: 13px;
    }
}

.buttons app-create-item-dropdown .dropdown-toggle {
    width: unset !important;
    height: 26px !important;
}

.no-prepend-picker {
    margin-left: 50px;
    max-width: 210px;
}

*:not(input) {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}


input {
    -webkit-user-select: text; /* Chrome, Opera, Safari */
    -moz-user-select: text; /* Firefox 2+ */
    -ms-user-select: text; /* IE 10+ */
    user-select: text; /* Standard syntax */
}


.disabled {
    opacity: .5;
    pointer-events: none;
    filter: grayscale(100);
}

.fak {
    min-width: 1rem;
    min-height: 1rem;
}

a.fal.disabled {
    cursor: not-allowed;
    opacity: .5 !important;
    color: $text-muted !important;
}

.ql-container {
    font-size: unset;
    font-family: unset;
}

.corner-item.disabled,
.corner-item.disabled > * {
    opacity: 1 !important;
}

.placeholder-sm::placeholder {
    font-size: .75rem;
    text-overflow: ellipsis;
}

.tiny-placeholder::before {
    content: " (" attr(placeholder) ")";
    border: 1px solid red;
    display: inline-block;
    color: red;
}

[data-placeholder] {
    font-size: .75rem;
}

[data-placeholder]:empty,
::placeholder,
.placeholder {
    font-style: italic;
    color: #868e96;
    font-size: .75rem;
    transform: translateY(-2px);
    background: unset;
    cursor: pointer;
}

::placeholder {
    font-size: .75rem;
}

.text-italic {
    font-style: italic;
}

.text-strong,
.text-bold {
    font-weight: bold;
}

@import "./ngx-bootstrap.scss";

button:focus,
button {
    outline: none;
}

.btn.fal {
    font-weight: 300;
}

.nav-item {
    //padding: 5px 0 0 5px;
}


.nav.nav-pills.nav-fill{
    gap: .5rem;
    .nav-item:has(.btn-danger){
        max-width: 40px;
    }
}







.debug {
    ul {
        list-style: none;
        padding: 0;

        li {
            margin: 0;
        }
    }

    border: 1px dotted $red;
    background-color: rgba(250, 10, 10, 0.2);
    border-color: rgba(250, 10, 10, 0.6);

    &.blue {
        border-color: #30526b;
        background-color: rgba(#30526b, 0.3);
    }

    &.green {
        border-color: #7a935c;
        background-color: rgba(#c1e783, 0.3);
    }

    &.hover {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $zindex-navigation;

        color: #fff !important;
        background-color: rgba(#1a3b46, 0.95);
        border: none;

        border-radius: .25rem;
        font-size: 65%;
        padding: .5rem;
        font-family: Monaco, "Lucida Console", monospace;

        > * {
            //display: none;
            color: #fff;
        }


    }
}

::selection :not(input::selection) {
    background: transparent;
}

.scroll-y {
    overflow: hidden;
    overflow-y: auto;
    overflow-scrolling: touch;
    scrollbar-color: rgba(255, 255, 255, 0.1) rgba(0, 0, 0, 0.05);
}


.scroll-x {
    overflow: hidden;
    overflow-x: scroll;
    overflow-scrolling: touch;

}


.wheel-scroll-wrapper,
.scroll-container.show-scroll,
.scroll-y.show-scroll {
    overflow-y: scroll;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        transform: scale(.95);

    }

    &::-webkit-scrollbar:vertical {
        width: 11px;
        width: 9px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 11px;
        height: 9px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #1a3b46;
        border: 1px solid #fff;
        transform: scale(.75);
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 8px;
        margin-bottom: 6px;
        margin-top: 6px;
        margin-left: 4px;
        margin-right: 4px;
    }
}


.scroll-x::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 8px;
    border-radius: .5rem;
    margin-bottom: .5rem;
}

.scroll-x::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1) !important; // manage scrollbar thumb background color here
    //border-radius: 0.5rem;
}

::-webkit-scrollbar,
.scroll-x::-webkit-scrollbar * {
    background: transparent; // manage scrollbar background color here
}

.scroll-x::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .2) !important;
    border-radius: 0.5rem;
}

.fa.fa-edit,
.cursor-pointer {
    cursor: pointer;
}

app-card-project:hover {
    z-index: auto;
}


label ::selection,
.nav-item ::selection,
.modal ::selection:not(quill-editor ::selection),
.cursor-pointer::selection {
    background: transparent;
}

.cursor-default {
    cursor: default !important;
}

.cursor-drag {
    cursor: grab !important;
}

.cursor-drag:active {
    cursor: grabbing !important;
}

.opacity-50{
    opacity: .5;
}
.faux-label,
label {
    color: $kb-blue;
    /*text-transform: uppercase;*/
    font-size: 80%;
    font-size: $font-size-medium;
    width: 100%;
}

.dark-theme {
    .faux-label {
        color: $white;
    }

    .input-group-text {
        background-color: rgba(255, 255, 255, .1);
        border-color: rgba(255, 255, 255, 0.2);
    }
}


body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
body .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
    font-family: 'Font Awesome 5 Pro' !important;
}

.dropdown-item,
a {
    cursor: pointer;
}

a,
a:hover {
    text-decoration: none;
}

.word-wrap {
    word-wrap: break-word;
    word-break: break-word;
}

.circle,
.circle:empty {
    width: calc(1em - 1px) !important;
    height: calc(1em - 1px) !important;
    padding: 0;
    border-radius: 50%;
    display: inline-block;
    margin-top: 1px;
    /*transform: translate(1px, 1px);*/
}

.text-small {
    font-size: 75%;
}

.text-12px {
    font-size: 12px;
    font-size: 14px;
    transform: scale(.8);
}

.text-13px {
    font-size: 13px;
}

.text-normal {
    font-size: 13px;
    font-weight: normal;
}

.text-large {
    font-size: 125%;
}

.text-12 {
    font-size: 12px;
    font-weight: normal;
}

.input-padding {
    padding: 0.375rem 0.75rem;
}


.light a.nav-item {
    color: $kb-blue;
}

.dark a.nav-item {
    color: $white !important;
    opacity: .8;

    &:hover {
        color: $white;
        opacity: 1;
    }
}

.dropdown-menu {
    user-select: none;
}

.modal-footer {
    flex-wrap: nowrap;

    > .btn {
        flex-shrink: 1;
        //flex-grow: 1;
    }
}

.dark-theme {

    .btn-outline-dark {
    }

    .btn-outline-dark {
        color: #fff;
        background-color: #1a3b46;
        border-color: #1a3b46;
        opacity: 1;
    }

    .modal-header,
    .modal-footer {
        border-color: rgba(0, 0, 0, .1) !important;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .modal-header {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }

    .light a.nav-item {
        color: $white;
    }


    .dark .navbar-nav .nav-link.active,
    .dark a.nav-item {
        color: $white !important;
        opacity: .8;

        &:hover {
            color: $white;
            opacity: 1;
        }
    }

    .rich-text-wrapper {
        background-color: rgba(255, 255, 255, .05) !important;
        color: $white !important;
    }

}

.comma-delimeter:not(:last-of-type)::after {
    content: ',';
    margin-right: .25rem;
}


ngb-typeahead-window {
    /*width: calc(100% - 38px);*/
    max-height: 250px;
    overflow-y: auto;
    overflow-scrolling: touch;
    z-index: $zindex-editor + 5;
}

ngb-highlight {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap !important;
    /* word-break*/
    > li {
        list-style-position: inside;
        white-space: nowrap;
    }
}

.office-logo {
    display: block;
    text-indent: -9999px;
    width: 100%;
    height: 100%;
    background: url('/assets/Microsoft_Office_2013_logo.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80% auto;
}

.rounded {
}

.clamp {
    /*
    overflow: hidden;
    height: 36px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    */

    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 2em;
    line-height: 1em;
    max-height: 2em;
    overflow: hidden;
    /*
    display: inline-block;
    width: 100%;
    max-width: 100%;
    min-width: 100px;
    word-break: break-word;
    white-space: normal;
    max-height: 2em;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    */

}


.bg-light .border-right,
.bg-light .border-left,
.bg-light .border-top,
.bg-light .border-bottom,
.border-color-white {
    /*border-color: $white!important;*/
}

.border-color-light {
    border-color: rgba(255, 255, 255, .5) !important;
}

.border-color-dark {
    border-color: rgba(0, 0, 0, .2) !important;
}

.ms-auto.text-small.cursor-pointer.btn.btn-sm.btn-micro.btn-light {
    font-size: 100%;
    position: absolute;
    top: 6px;
    right: 0;
}

.corner-item {
    position: absolute !important;
    top: -1px;
    right: -1px;
    opacity: .95;
    //color: $white !important;
    //background-color: $kb-blue !important;
    //background-color: $kb-dusty !important;
    border-radius: 0 0 0 3px !important;
    width: 16px;
    font-size: 1rem !important;
    height: 18px;
    z-index: 1;
    margin: 0 !important;
    //border-left: 2px solid $kb-blue;
    //border-bottom: 2px solid $kb-blue;
    //border-bottom: 2px solid $kb-blue;
    color: $kb-blue !important;
    background: transparent;

    &.left {
        right: unset;
        left: -5px;
        border-radius: 0 3px 0 0 !important;
        border-radius: 4px !important;
        width: auto;
    }

    &.disabled {
        pointer-events: none;

        > * {
            opacity: .5;
        }
    }

}

.action-panel .fas,
.action-panel .fal,
.action-panel .fa {
    font-size: .8rem !important;
}


.scale-2x-wrapper {
    //border: 1px solid red;
    width: 400px;
    min-height: 140px;

    .card-wrap {
        transform-origin: top left;
        transform-style: flat;
        transform: scale(2);
    }
}

.popover-transparent {

    background-color: transparent !important;
    border: none !important;

    pointer-events: none;

    .popover-arrow {
        display: none !important;
    }

    .popover-body {
        //border: 1px solid red;
        //transform-origin: top left!important;
        ::ng-deep .card {
            transform: scale(2) !important;
            box-shadow: 0 2px 8px rgba(0, 0, 0, .5);
        }

    }
}

// only if :has(.list-counter) and list-coutner is visible
.footer-padding {
    padding-bottom: 2rem;
}

.action-panel.no-hover {
    transform: unset;
    opacity: 1;
}

.action-panel.no-fade {
    background: transparent !important;
}

*:hover > .action-panel.no-fade .corner-counter {
    opacity: 1;
}

.action-panel.no-fade .corner-counter {
    transition: all .5s ease-out;
    opacity: 1;
    pointer-events: none;
}

.action-panel a,
.action-panel.no-fade li:hover * {
    color: $kb-blue;
    opacity: 1;
}

//.action-panel:hover a{
//    color: $white;
//}

.datatable-body {
    background-color: $white;
}

.ngx-datatable.bootstrap .datatable-footer {
    padding-bottom: 1px;
    border-top: 1px solid rgba(0, 0, 0, .6);
}


.dropdown-wrapper {
    border: none;
    overflow: hidden;
    border-radius: .5rem;

    > hr {
        display: none;
    }
}

.dropdown-wrapper .dropdown-item {
    padding: 2px 4px 2px 4px;
    border-bottom: 1px solid $border-light-black;

    > * {
        padding: 4px 8px 4px 8px;
    }
}

.dropdown-wrapper .dropdown-item:last-of-type {
    border: none;
}

.dropdown-wrapper app-dropdown-list-item {
    display: block;
    border: none;
}

.dropdown-wrapper app-dropdown-list-item:last-child {
    border-radius: 0 0 0.25rem 0.25rem;
    overflow: hidden;
}

.dropdown-wrapper.dropdown-menu .dropdown-item:hover {
    background: $kb-light-blue !important;
    color: $kb-blue !important;

    .ellipsis {
        text-decoration: underline;
    }

    //font-weight: bold;
}

*:hover > .action-panel.no-fade .corner-counter {
    background: $border-light-white-outline;
    color: $kb-blue;
}

.action-panel.no-fade .corner-counter {
    background: rgba(255, 255, 255, .05);
    color: rgba(0, 0, 0, .3);

    background: $border-light-white;
    color: rgba(0, 0, 0, .8);
    border-color: $kb-blue;
    color: $kb-blue;
}

.action-panel:hover .corner-counter {
    opacity: 1;
}

.action-panel:empty {
    display: none !important;
}

.action-panel:hover {
    //background: rgba(26, 59, 70, .95);
    //border: 1px dashed rgba(26, 59, 70, 0);
    //color: #fff;
}

//.w-100.h-100:has(.action-panel),
.note-wrapper
{
    //border: 1px solid red!important;
    position: relative;
    transition: all .25s ease-in-out;
    flex-grow: 1;
    width: 100%;

    .rich-text-wrapper{
        overflow-y: auto;
        justify-content: flex-start;

        h1{
            font-size: 1.25rem;
        }
        h2{
            font-size: 1.125rem;
        }
        h3{
            font-size: 1rem;
        }
        h4{
            font-size: .875rem;
        }
        h5{
            font-size: .875rem;
        }

        .edit-content.text-truncate:has(h5){
            min-height: .875rem!important;
        }

        .edit-content.text-truncate:has(h4){
            min-height: 1.25rem!important;
        }

        .edit-content.text-truncate:has(h3){
            min-height: 1.25rem!important;
        }
        .edit-content.text-truncate:has(h2){
            min-height: 1.5rem!important;
        }

        .edit-content.text-truncate:has(h1){
            min-height: 1.75rem!important;
        }


    }

    .action-panel {
        transform: translateY(0px);
        //opacity: 1!important;
        //border: 1px solid green;
        margin: 0;
        position: absolute;
    }

    .flex-fill{
        .body{
        }
    }

    &:has(.rich-text-wrapper.expanded){
        margin-bottom: .5em;
    }
    .rich-text-wrapper.expanded{
        max-height: 100vh;
    }
    .rich-text-wrapper .date{
        position: absolute;
        top: 0.75rem;
        right: 0.5rem;
        opacity: 0;
        transition: all .25s ease-in-out;
        background: $kb-blue;
        color: $white;
        border-radius: .25rem;
        padding: .25rem .5rem;
    }
    &:hover {

        :has(.date){
            //padding-bottom: 20px;
        }

        .rich-text-wrapper{
            outline: 3px solid $kb-blue;
            box-shadow: 0 0 0 3px rgba(26, 59, 70, 0.15);

            .date{
                opacity: 1;
            }
        }
        padding-top: 2rem;
        .action-panel {
            transform: translateY(-.25rem);
        }
    }

}
.action-panel {
    //transform: translateY(-16px);
    position: absolute;

    border: 1px dashed rgba(26, 59, 70, 0.15);
    background: rgba(26, 59, 70, .05);
    color: rgba(26, 59, 70, 0.15);

    background: #1a3b46;
    color: #fff;

    a{
        color: #fff;
    }

    filter: none;

    top: 0.25rem;
    left: .25rem;
    z-index: 8;
    padding: .25rem !important;
    font-size: 12px;
    border-radius: 8px !important;
}


.action-panel.placement-bottom {
    top: unset;
    bottom: .25rem;
    transform: translateY(16px);
}

.action-panel.placement-top-right {
    left: unset;
    top: .25rem;
    right: .25rem;
}

.action-panel.placement-top-right.corner {
    left: unset;
    top: -0.25rem;
    right: -0.25rem;
    transform: translate(0.25rem, -0.25rem) !important;
}

.action-panel.placement-bottom-right {
    left: unset;
    top: unset;
    bottom: .25rem;
    right: .25rem;
}

.action-panel.border-none {
    border-color: transparent;

    &:hover {
        //border: 1px solid rgba(255,255,255,.1)!important;
    }

}

.action-panel.no-fade {
    transform: translateY(8px) !important;
    opacity: 1 !important;
}

:hover > .action-panel {
    transform: translateY(0px);
}

.action-panel.no-fade.solid {
    border: none !important;
}

.action-panel.no-fade.solid * {
    color: $kb-blue;
    border-color: transparent;
}

.dark-theme {

    .datatable-body {
        background-color: $kb-dark-gray-500;
    }

    .action-panel {
        position: absolute;

        border: 1px dashed $border-light-white;
        background: rgba(0, 0, 0, .05);
        color: rgba(255, 255, 255, 0.15);
    }

    .action-panel.no-fade,
    .action-panel:hover {
        background: rgba(24, 23, 23, 0.95);
        border: 1px dashed rgba(255, 255, 255, 0);
        color: #fff;
    }

    .action-panel a,
    .action-panel.no-fade li:hover * {
        color: #fff;
        opacity: 1;
    }

    .action-panel.no-fade.solid * {
        color: #fff;
    }


    *:hover > .action-panel.no-fade .corner-counter {
        background: #fff;
        color: #fff;
    }

    .action-panel.no-fade .corner-counter {
        background: $border-light-white;
        color: $border-light-white;
        border-color: $border-light-white !important;

        background: $border-light-white !important;
        color: rgba(255, 255, 255, .8);
        border-color: rgba(255, 255, 255, .8) !important;

    }
}

:hover > .action-panel,
.list-container:hover app-create-item-dropdown {
    pointer-events: all;
    opacity: 1 !important;
}

.action-panel,
.list-container app-create-item-dropdown {
    transition: .25s ease-in-out all;
    pointer-events: none;
    opacity: 0.0;
}

.action-panel.no-hover {
    pointer-events: auto;
    left: unset;
    top: unset;
}

.list-container app-create-item-dropdown {
    opacity: 1 !important;
}


.corner-item:hover {
    opacity: 1;
    //color: white !important;
    //background-color: $kb-blue!important;
    .btn,
    > * {
        //color: white !important;
        //color: $kb-blue !important;
    }
}

.corner-item.wide {
    width: auto;

}

.text-capitalize-firstletter:first-letter {
    text-transform: capitalize;
}

.hover-show {
    cursor: default;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    overflow: hidden;

    > * {
        opacity: 0;
        transition: all 0.1s ease-in-out;
        transition-delay: 0.5s;
        transform: translateY(100%);
    }


    &:hover {
        opacity: 1;

        > * {
            transform: translateY(0%);
            opacity: 1;
        }
    }
}

.loader-percent {
    position: absolute;
    width: 180px;
    border-radius: .8rem;
    background-color: rgba(0, 0, 0, 0.75);
    background-color: $kb-blue;
    color: $white;
    left: calc(50% - 90px);
    top: 30%;
    z-index: $zindex-navigation;
}

.text-black-25 {
    color: rgba(0, 0, 0, 0.25);
}

.ql-toolbar {
    border-radius: 4px 4px 0 0;
    background-color: $gray-200;
    background-color: $kb-background-blue;
    border-color: rgba(0, 0, 0, 0.125) !important;
}

.ql-container {
    border-radius: 0 0 4px 4px;
    background-color: $white;
    border-color: rgba(0, 0, 0, 0.125) !important;

}

.ql-editor {
    font-family: "Bai Jamjuree", "Open Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    cursor: text;
    display: block;
    position: relative;
    min-height: 1rem;
}

.ql-editor.ql-blank::before {
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-toolbar button.ql-active {
    color: #005f76 !important;
    stroke: #005f76 !important;
}

.dropdown-menu {
    .dropdown-item,
    a.dropdown-item {
        color: $kb-dark-gray-500 !important;
        background-color: $white !important;

        &:active {
        }
    }

}


.form-control:disabled, .form-control[readonly],
.ql-container,
.list-group-item,
.form-control,
.form-control input {

    &.focus,
    &:focus {
        color: $gray-700;
        background-color: $white;
        border-color: $kb-cyan;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(26, 59, 70, 0.25);
    }

}

.popover:has(.milestone-plan-selector),
.popover:has(.filter-color-popover),
.popover:has(input[type="search"]),
.popover:has(.emoji-popover),
.popover:has(.tabs-container) {
    border-radius: .75rem;

    &.bs-popover-bottom-end {
        border-top-right-radius: 0;
    }
}

.dark-theme {
    .border {
        border-color: rgba(255, 255, 255, .25) !important;
    }

    .text-dark {
        color: white !important;
    }

    .faux-label:not(.list-group-item .faux-label),
    label:not(.list-group-item label) {
        color: $white !important;
    }

    .modal-content {
        background-color: $kb-blue !important;
        background-color: #1a3b46 !important;
        color: $white !important;
    }

    .tabs-container.outline .tab-content, .popover .tabs-container .tab-content {
        background-color: rgba(0, 0, 0, 0.1) !important;
        border-width: 0 !important;
    }

    .popover {
        color: $white !important;

        > *,
        .popover-title {
            color: $white !important;
        }
    }

    .bs-popover-top > .popover-arrow::after,
    .popover.bs-popover-top .popover-arrow::before,
    .tooltip.bs-tooltip-top .tooltip-arrow::before {
        border-top-color: $kb-dark-gray-500 !important;
    }

    .bs-popover-bottom > .popover-arrow::after,
    .popover.bs-popover-bottom .popover-arrow::before,
    .tooltip.bs-tooltip-bottom .tooltip-arrow::before {
        border-bottom-color: $kb-dark-gray-500 !important;
    }

    .bs-popover-start > .popover-arrow::after,
    .popover.bs-popover-start .popover-arrow::before,
    .tooltip.bs-tooltip-start .tooltip-arrow::before {
        border-left-color: $kb-dark-gray-500 !important;
    }

    .bs-popover-end > .popover-arrow::after,
    .popover.bs-popover-end .popover-arrow::before,
    .tooltip.bs-tooltip-end .tooltip-arrow::before {
        border-right-color: $kb-dark-gray-500 !important;
    }

    .corner-item {
        color: #fff !important;
        opacity: .75;

        &:hover {
            opacity: 1;
        }

    }

    .dropdown-menu {
        .dropdown-item,
        a.dropdown-item {
            background-color: $kb-dark-gray-500 !important;
            color: $white !important;

            &:active {
            }
        }

    }


    .bs-tooltip-auto[x-placement^=top] .popover-arrow::before,
    .bs-tooltip-top .popover-arrow::before {
        border-top-color: $white !important;
        border-top-color: $kb-dark-gray-500 !important;

    }

    .bs-tooltip-right .popover-arrow::before {
        border-right-color: $white !important;
        border-right-color: $kb-dark-gray-500 !important;
    }

    .bs-tooltip-bottom .popover-arrow::before {
        border-bottom-color: $white !important;
        border-bottom-color: $kb-dark-gray-500 !important;
    }

    .bs-tooltip-left .popover-arrow::before {
        border-left-color: $white !important;
        border-left-color: $kb-dark-gray-500 !important;
    }


    .tooltip-inner {
        background-color: $white !important;
        color: $kb-blue !important;
    }

    app-overlay-project-planning,
    .nav-tabs,
    .sidebar-wrapper,
    .items > *,
    .items ul {
        background-color: $kb-blue !important;
        color: $white !important;
    }

    app-overlay-project-planning td,
    app-overlay-project-planning .flex-cell,
    td,
    .flex-cell,
    .nav-tabs,
    .nav-item {
        border-color: $kb-dark-gray-500 !important;
    }


    .tabs-editor {

        .nav-link.active {

        }

        li.nav-item:before,
        li.nav-item:after {
            //background: $kb-sidebar-gray;
            //background: $kb-dark-gray-500 !important;
            background: $header-color !important;
        }

        .nav-link.active:before,
        .nav-link.active:after {
            background: $kb-dark-gray-500 !important;
            background: $kb-dark-gray-500 !important;
        }

        .nav-link {
            background: $kb-dark-gray-500 !important;

        }

        .nav-link.active {
            border-radius: .5rem .5rem 0 0 !important;
            background: $header-color !important;

            > * {
                color: $white !important;
            }
        }

        .popover .tabs-container .nav-link.active {
            border-radius: .4rem .4rem 0 0 !important;
        }

    }

    ::ng-deep {
        .tabs-editor {
            .nav-link.active {
                border-radius: .5rem .5rem 0 0 !important;
                background: $header-color !important;
                color: $white !important;
            }
        }
    }

    .card {
        color: $kb-light-blue;
        border-color: rgba(255, 255, 255, .25);
        background-color: $kb-blue;

        .phase.border-top,
        .border-top,
        .border-bottom,
        .border {
            border-color: rgba(255, 255, 255, .25) !important;
        }

    }

    .dropdown-wrapper {
        .border-top,
        .border-bottom,
        .border {
            border-color: rgba(255, 255, 255, .25) !important;
        }
    }

    .text-black-25 {
        color: rgba(255, 255, 255, .25) !important;
    }

    .text-black-50 {
        color: rgba(255, 255, 255, .5) !important;
    }

    .ngb-dp-weekday,
    .btn-light {
        color: $white;
        border-color: rgba(255, 255, 255, .5) !important;
    }

    .close {
        color: #fff;
        text-shadow: none;
    }


    .mat-drawer-container {
        color: $white !important;
    }

    .ngb-dp-weekday, .btn-light {
        border-color: $kb-blue;
        background-color: $kb-blue;
        color: $white;
    }

    .popover,
    app-root,
    app-todo-editor,
    app-project-editor,
    app-milestone-editor,
    app-settings-editor,
    app-task-editor {

        .modal-content,
        .container {
            background-color: $kb-blue !important;
            background-color: #1a3b46 !important;
            color: $white !important;
        }


        .card-header,
        .card-header h4::after {
            //background-color: $kb-blue !important;
            background-color: $kb-dark-gray-500 !important;
            border-color: $kb-dark-gray-500 !important;
            color: $white !important;
        }

        .form-control:disabled, .form-control[readonly],
        .ql-container,
        .list-group-item,
        .form-control,
        .form-control input {
            background-color: $border-light-white;
            color: $white;
            border-color: rgba(255, 255, 255, 0.2);

            &.focus,
            &:focus {
                background-color: rgba(255, 255, 255, 0.3);
                border-color: rgba(255, 255, 255, 0.8);
                color: $white;
            }

        }

        .form-control[readonly] {
            background-color: rgba(255, 255, 255, 0.05);

            &.focus,
            &:focus {
                background-color: rgba(255, 255, 255, 0.05);
            }
        }

        .resize-handle-bottom {
            color: #fff;
        }

        .form-control-text,
        label {
            color: $white;
        }
    }

    .btn-outline-primary {
        color: rgba(255, 255, 255, .8) !important;
        border-color: rgba(255, 255, 255, .8) !important;
    }

    .text-black-50 {
        color: rgba(255, 255, 255, .5) !important;
    }


    svg {
        fill: $white !important;
    }

    .ql-snow .ql-stroke {
        stroke: $white !important;
    }

    .ngb-dp-weekdays,
    .ngb-dp-weekdays > * {
        background-color: $kb-dark-gray-500 !important;
    }

    .ql-container {
        background-color: rgba(255, 255, 255, 0.1) !important;
        //border-color: rgba(0, 0, 0, 0.125) !important;
        border-color: rgba(255, 255, 255, 0.2) !important;
    }

    .tooltip {
        .tooltip-inner {
            background-color: $kb-dark-gray-500 !important;
            color: $white !important;
        }
    }

    .popover,
    .ngb-dp-header,
    .ql-toolbar,
    .dropdown-menu {
        .border-bottom {
            border-color: rgba(255, 255, 255, .2) !important;
        }

        .btn,
        a.dropdown-item,
        .dropdown-item,
        h5 {
            color: $white !important;
        }


        .dropdown-item:hover {
            background-color: rgba(255, 255, 255, 0.05) !important;
            color: $white !important;
        }

        hr {
            color: #314f59;
            display: none;
        }

        border: 1px solid rgba(255, 255, 255, .2);
        font-size: 13px;
        background-color: $kb-dark-gray-500 !important;
        color: $white !important;
    }

    .popover {
        border-width: 0;
        box-shadow: 0 0 1px rgba(255, 255, 255, .3);
    }


    .form-control-text {
        //color: $white;
    }

    .loader-percent {
        background-color: rgba(255, 255, 255, 0.95) !important;
        color: $kb-blue !important;
    }


    .dashboard-wrapper {
        .sidebar-wrapper {
        }
    }

    app-dashboard-header > div {
        background-color: unset !important;
        color: #fff;
    }

    .disable-resize .datatable-body-row,
    .disable-resize .datatable-row-center {
        //width: unset!important;
    }

    .resizing .datatable-body-row,
    .resizing .datatable-row-center {
        width: unset !important;
    }

    //.ngx-datatable:not(.disable-resize) .datatable-body-row,
    .ngx-datatable .datatable-row-center {
        //width: unset!important;
    }

    .ngb-dp-header {
        .form-select {
            color: #fff;
            background-color: rgba(255, 255, 255, .25);
        }

        .form-select:focus {
            border-color: $white;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba($kb-blue-secondary, .25);
        }
    }

    .btn-check:focus + .btn, .btn:focus {
        box-shadow: 0 0 0 0.25rem rgba($kb-blue-secondary, .25);
    }


    .ngx-datatable.bootstrap,
    .bg-white,
    .bg-light,
    .dashboard-wrapper,
    body,
    .ngx-datatable.bootstrap .datatable-body .datatable-body-row {
        color: $kb-light-blue;
        background-color: #1d2931 !important;
        background-color: #0f2d37 !important;
        border-color: $border-light-white !important;

        .datatable-body-cell.cell-light,
        .datatable-body-cell.cell-dark,
        .datatable-body-cell {
            border-color: $border-light-white !important;
            background-color: unset !important;
        }

        .datatable-body-cell.cell-light {
            //background-color: #1a3b46 !important;
        }
    }

    .ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-odd .datatable-body-cell {
        background-color: darken(#1d2931, .1) !important;
    }

    .content-wrapper {
        border: none !important;
    }

    .flex-column .fa-hand-rock,
    .flex-column .light a {
        color: #fff !important;
    }

    .navigation-wrapper {

        .list-unstyled a {
            opacity: 1;
        }

        .list-unstyled a i,
        .list-unstyled a span {
            color: #fff !important;

        }
    }
}

.ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
    margin: 1px;
}

.list-group {
    border-radius: .5rem;
}

.dropdown-menu {
    border-radius: .5rem;

    .ngb-dp-header {
        border-radius: .5rem .5rem 0 0;
        padding-top: .5rem;

        .form-select {
            //background-color: rgba(255,255,255,.25);
        }

        .form-select:first-of-type {
            margin-right: .25rem;
        }
    }
}

.btn-link {
    color: $darkblue;
}


.ngx-datatable.bootstrap {
    contain: strict;
}

.d-print-block {
    display: none;
}

.modal-dialog > .modal-content {
    box-shadow: 0 2px 8px $border-light-black;
    border-radius: .5rem;
}

.dark-theme .modal-dialog > .modal-content .nav-pills.nav.position-absolute {
    border-radius: 0 0 .45rem .45rem;
}

.dark-theme .modal-dialog > .modal-content {
    box-shadow: 0 0 0 1px #536c74, 0 2px 8px $border-light-black;
}

.modal-dialog > .modal-content {
    border: none !important;
}

.router-wrap {
}

.main-wrapper {
    height: calc(100vh - 66px);
}


/* Overlay */

.cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.cdk-overlay-container {
    position: fixed;
    z-index: 1000
}

.cdk-overlay-container:empty {
    display: none
}

.cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000
}

.cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%
}

.cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
    opacity: 0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1
}

@media screen and (-ms-high-contrast: active) {
    .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: .6
    }
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, .32)
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0
}

.cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px
}

.cdk-global-scrollblock {
    position: fixed;
    width: 100%;
    overflow-y: scroll
}

@keyframes cdk-text-field-autofill-start { /*!*/
}

@keyframes cdk-text-field-autofill-end { /*!*/
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
    animation-name: cdk-text-field-autofill-start
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
    animation-name: cdk-text-field-autofill-end
}

textarea.cdk-textarea-autosize {
    resize: none
}

textarea.cdk-textarea-autosize-measuring {
    height: auto !important;
    overflow: hidden !important;
    padding: 2px 0 !important;
    box-sizing: content-box !important
}

.mat-ripple-element {
    background-color: $border-light-white;
}

.container-fluid.is-loading {
    opacity: .5;
}


@media print {

    app-shell,
    .page-container {
        display: flex;
        min-height: 100% !important;

        .mat-drawer-container {
            height: 100% !important;
        }
    }
    .header-wrap,
    app-header {
        display: none !important;
    }

    .mat-drawer-container {
        min-height: fit-content;
        height: fit-content !important;
        //min-height: 100vh!important;

    }

    .content-container {
        //min-height: 100vh!important;
        min-height: fit-content !important;
        overflow: visible !important;
        min-height: 100vh !important;
        height: unset !important;
    }
    .mat-drawer-inner-container,
    body,
    .ngx-datatable.scroll-vertical .datatable-body {
        overflow-y: visible !important;
    }

    .router-wrap {
        //@include debug;
    }

    .d-print-block {
        display: block;
    }
    @page {
        size: A4 landscape;
        //margin: 0;
    }
    //.wrapper.h-100,
    //.content-wrapper.h-100,
    .scroll-y,
    .table-container.h-100 {
        height: unset !important;
        overflow: unset !important;
        max-height: unset !important;
    }

    .container-fluid {
        overflow: unset !important;
        max-height: unset !important;
    }

    html, body {
        //width: 210mm;
        //height: 297mm;
        min-height: 297mm;
    }
    body {
        overflow: visible;
    }
    .milestones-row:last-child {
        padding-bottom: 120px;
    }

    .cdk-global-scrollblock {

    }
    .cdk-global-overlay-wrapper {
        position: relative;
    }
    .container-fluid {
        //width: 210mm;
        //height: 297mm;
        //overflow-y: auto;
    }

    .datatable-scroll{
        width: 100%!important;
        display: block!important;
        page-break-inside: auto;
    }

    .sub-displays-wrapper,
    datatable-selection,
    table,
    figure {
        page-break-inside: auto;
    }
    datatable-body-row{
        display: block;
        page-break-after: auto;
    }
    datatable-body-row,
    .card {
        page-break-inside: avoid;
    }

    .ngx-datatable .datatable-body .datatable-row-wrapper,
    .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row,
    .datatable-row-wrapper,
    .flex-table.rowspan {
        page-break-inside: avoid;
        //page-break-after: auto;
        position: relative;
        //display: block !important;
        width: 100%;
        //background-color: red!important;
    }


    input[type="search"],
    .navigation-options,
    .corner-item,
    .btn,
    .d-print-none {
        display: none !important;
    }

    .cdk-overlay-container {
        position: relative !important;
        overflow: unset !important;
    }
    .cdk-global-scrollblock,
    .overlay-ignore-z {
        width: 100%;
        height: unset !important;
        overflow: unset !important;
        position: relative;
        page-break-inside: auto;
    }
    .ngx-datatable {
        overflow: visible !important;
        page-break-inside: auto;
    }
    .flex-table.rowspan {
        page-break-inside: avoid;
        page-break-after: auto;
    }

    .overlay app-shell {
        display: none;
    }
    * {
        color-adjust: exact;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    .cdk-overlay-container {
        background-color: white !important;
        align-self: baseline;
    }
}


a {
    color: unset;

    &:hover {
        color: unset;
    }
}


.modal-content .fade {
    transition: none !important;
}


.input-group-append .btn {
    height: 100%;
}


.tabs-container.outline,
.popover .tabs-container {
    background: $header-color !important;
    border: 2px solid $header-color !important;
    border-radius: .5rem;

    .tab-content {
        background: $kb-sidebar-gray !important;
        background: $kb-background-blue !important;
        padding: .5rem;
        border-radius: 0 0 .4rem .4rem;
    }

    &[data-items="1"] {
        > ul {
            display: none;
        }

        .tab-content {
            border-radius: .4rem;
        }
    }

    h6 {
        font-size: 12px;
    }

    .rounded {
        border-radius: .3rem !important;
    }

}

.dark-theme .tabs-container.outline,
.dark-theme .popover .tabs-container {
    .tab-content {
        //background: unset!important;
    }
}

.dark-theme app-display-project-details,
.dark-theme app-milestone-plan-editor {
    .tabs-container.outline .nav-tabs {
        background-color: transparent !important;
        border: none !important;;

        column-gap: 0.25rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;

        .nav-link.active:before, .nav-link:after,
        .nav-item:before,
        .nav-item:after {
            display: none !important;
        }

        .nav-item > a {
            background-color: transparent !important;
        }

        .nav-link,
        .nav-item {
            background-color: #1d2931;
            background-color: #0f2d37;
            border-radius: .5rem !important;
            height: 100%;

            > a {
                border: none !important;
            }

            //background-color: red!important;
        }

        .nav-link.disbled.active,
        .nav-link.active,
        .nav-item.disbled.active,
        .nav-item.active {
            background-color: #314f59 !important;


            //background-color: blue!important;
        }
    }

    .tabs-container.outline,
    .popover .tabs-container {
        .tab-content {
            background: unset !important;
        }
    }
}

.tabs-editor {
    padding: 0;


    .justify-content-start {
        /*justify-content: space-between !important;*/

    }

    .tab-content {
        /* padding-top: 70px; */
        position: relative;

    }


    .nav-tabs {

    }

    .nav-item {
        margin: 0;
        padding: 0;
        width: auto;
        flex: 1 auto;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-top: none !important;
        //font-weight: 200;
        transition: none !important;
    }

    .nav-link {
        transition: none !important;
    }

    .nav-item.active {
        font-weight: 600;
    }

    .nav-item:not(.active) {

    }

    //.nav-item:not(.nav-item + .nav-item.active),
    //.nav-item + .nav-item.active,
    //.nav-item.active + .nav-item

    .nav-item.active > a {
        border: none !important;
    }


    .nav-item:last-of-type {
        border-right: none;
    }

    .nav-item.active ~ .nav-item:not(:last-of-type) {
        > a {
            border: none !important;
            //color: green !important;
            border-right: 1px solid #314f59 !important;
        }
    }

    .nav-item.active + .nav-item:not(.nav-item:first-child):not(.nav-item.active) {
        > a {
            //border: none !important;
            //border-right: 1px solid #314f59!important;
            //color: red !important;
        }
    }

    //.nav-item:not(.nav-item:first-child):not(.nav-item.active) > a{
    //    border-right: 1px solid #314f59!important;
    //    &:last-of-type > a{
    //        border-right: none;
    //    }
    //}

    .nav-item:has(~ .nav-item.active) {
        > a {
            //border: none !important;
            //color: blue !important;
            border-right: 1px solid #314f59 !important;
        }
    }

    .nav-item:has(+ .nav-item.active) {
        > a {
            //border: none !important;
            //color: orange !important;
            border-right: none !important;
        }
    }


    .nav-item.active ~ .nav-item {
        //border-color: green!important;
    }

    .nav-item.active + .nav-item,
    .nav-item + .nav-item.active,
    .nav-item.active {
        //border: none!important;
        //border-color: red!important;
    }


    .nav-item:not(.active) {
        //border-right: 1px solid $header-color;
    }

    .nav-item:not(.active) > *:hover {
        text-decoration: underline;

    }

    .nav-link.active {
        background: $kb-sidebar-gray;
        background: $kb-background-blue;
        color: $darkblue !important;
        border-radius: .5rem .5rem 0 0 !important;
        position: relative;
        border: none;
        isolation: auto !important;
    }


    li.nav-item {
        position: relative;
    }

    li.nav-item.active:before,
    li.nav-item.active:after,
    .nav-link.active:before,
    .nav-link.active:after {
        /* All pseudo elements are
        abs. positioned and on bottom */
        position: absolute;
        bottom: 0;

        /* Squares below circles */

        content: "";
    }


    li.warning.nav-item > a > span {
        width: 100%;
    }

    li.warning.nav-item.active > a > span:after {
        color: $red;
    }

    li.warning.nav-item > a > span:after {
        font-family: "Font Awesome 5 Pro";
        font-family: "Font Awesome 6 Pro";
        font-weight: 400;
        content: "\f06a";

        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        color: $white;
        //transform: translate(22px, -9px);
        position: absolute;
        top: 0;
        right: 3px;

    }

    li.nav-item:before,
    li.nav-item:after {
        background: $kb-sidebar-gray;
        //background: green;
        z-index: 1;
        width: .5rem;
        height: .5rem;
    }

    .nav-link.active:before,
    .nav-link.active:after {
        width: 1rem;
        height: 1rem;
        background: $header-color;
        //background: red;
        z-index: 2;
    }


    .nav-link.active:before,
    .nav-link.active:after {
        border-radius: .5rem;
    }

    li.nav-item:after {
        right: -.5rem;
    }

    li.nav-item:before {
        left: -.5rem;
    }

    .nav-link.active:after {
        right: -1rem;
    }

    .nav-link.active:before {
        left: -1rem;
        bottom: 0;
    }


    .nav-link.active,
    .nav-link {
        border-radius: 0;
        border: none;
        color: $white;
        width: 100%;
        text-align: center;
        cursor: pointer;
    }

    .nav-tabs {
        background: $header-color !important;
    }

}

::ng-deep {

    .tabs-editor {
        li.nav-item:before,
        li.nav-item:after {
            //background: $kb-sidebar-gray;
            //background: $kb-dark-gray-500 !important;
            background: $header-color !important;
        }

        .nav-link.active:before,
        .nav-link.active:after {
            background: $kb-dark-gray-500 !important;
            background: $kb-dark-gray-500 !important;
        }

        .nav-link {
            background: $kb-dark-gray-500 !important;
        }

        .nav-link.active {
            border-radius: .5rem .5rem 0 0 !important;
            background: $header-color !important;
            color: $white !important;
        }

        .popover .tabs-container .nav-link.active {
            border-radius: .4rem .4rem 0 0 !important;
        }

    }
}


.container.container-sm {
    max-width: 540px;
    width: 540px !important;
}


.dropdown-item {
    padding-left: .5rem;
    padding-left: 0;
    padding-right: .5rem;
    > *:first-child{
       padding-left: .5rem;
    }
}

.height-unset {
    height: unset !important;
}


app-card-todo + .action-panel,
app-card-project + .action-panel,
app-card-task + .action-panel,
app-card-milestone + .action-panel {
    //right: 0px;
    //left: unset;
    //top: 0px;
    //bottom: unset;
    display: none !important;
}

app-card-todo:hover + .action-panel,
app-card-project:hover + .action-panel,
app-card-task:hover + .action-panel,
app-card-milestone:hover + .action-panel {
    //opacity: 0;
}


.tabs-container .datatable-header {
    border: none !important;
}

.column-title {
    @include ellipsis;
    align-self: center;
    flex-wrap: nowrap;
    //@include debug;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;

    > span:not(.rounded.border):not(.circle) {
        width: 100%;
        @include ellipsis;
    }

    .column-sorter > .dropdown-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        > * {
            flex-shrink: 1;
            @include ellipsis;
        }
    }
}

[ng-reflect-resizeable="true"] .column-title {
    padding-right: 20px !important;

}

app-column-sorter.filter-selector {
    width: calc(100% - 48px) !important;
}


.ngx-datatable.bootstrap .datatable-header {
    .datatable-header-inner {
        height: 28px;
    }

}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
    padding: 0 !important;
    //padding-top: 2px;
    //padding-left: .25rem!important;
    //padding-right: .25rem!important;
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: start;
    min-height: 28px;

    > .datatable-header-cell-template-wrap {
        //@include debug;
        display: flex;
        align-items: start;
        align-items: center;
        height: 100%;
        padding: 0;
    }
}

.datatable-header-cell-template-wrap {
}


.exporting .datatable-flex-fill .datatable-scroll {
    height: unset;
    display: block !important;
    justify-content: unset !important;
}

.datatable-min-height .datatable-scroll {
    min-height: 100%;

}

.datatable-min-height .datatable-footer {
    position: absolute;
    bottom: 0;
    height: 30px !important;
}

//  https://podio.com/klartboard/softwareudvikling/apps/supports/items/1104
.datatable-flex-column {
    datatable-body {
        padding-bottom: 0 !important;
    }

    datatable-selection {
        display: flex;
        height: 100%;
    }

    .datatable-scroll {
        display: flex !important;
        flex-direction: column;

    }

    .datatable-row-wrapper {
        //flex-grow: 1;
        // Fyld højde
    }
}

.datatable-flex-fill .datatable-scroll {
    //height: 100%;
    display: flex !important;
    justify-content: start;
}

ngx-datatable-column > * {
    height: 100%;
}

.ngx-datatable .datatable-body-row {
    //border: 1px solid red; /* DEBUG */
    min-width: 100%;
}


.datatable-body-cell {
    flex-grow: 1 !important;
    //border: 1px solid green;  /* DEBUG */
}

.ngx-datatable .datatable-body-row,
ngx-datatable-column {
    flex-grow: 1;
    width: 100%;
}

.datatable-scroll,
.datatable-header-inner {
    min-width: 100%;
}

.datatable-row-center {
    flex-grow: 1;
}

.sort-btn {
    display: none !important;
}


.popover-title {
    //background: $gray-300;

}

.popover-title {

    color: $kb-blue;
    border-radius: .25rem;
    padding: .5rem !important;
    font-weight: 300;
    //opacity: .75;
    font-size: 11px !important;
    text-align: left;
    padding-top: 0 !important;

    &:before {

        font-family: "Font Awesome 6 Pro";
        font-weight: 300 !important;
        content: "\f05a";
        margin-right: .25rem;

        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
    }

    &:has(.fal){
        &:before {
            display: none!important;
        }
    }




}


.rounded-pill.badge-danger {
    //background: $yellow;
    //color: $black;
}

.badge.bg-warning {
    color: $black;
}

.rounded-sm {
    border-radius: .5rem !important;
}

.callout {
    border-left: 2px solid $kb-blue-secondary;
}


.fit-content.badge.rounded-pill {
    background-color: $kb-blue !important;
    color: $white !important;
}

.fit-content.badge.rounded-pill.badge-warning {
    background-color: $kb-dusty !important;
    color: $gray-900 !important;
}

.fit-content.badge.rounded-pill.bg-success {
    background-color: $green !important;
}

.fit-content.badge.rounded-pill.bg-danger {
    background-color: $red !important;
}

.fit-content.badge.rounded-pill.bg-warning {
    background-color: $kb-dusty !important;
    color: $gray-900 !important;
}


.sticky-formgroup {
    position: sticky;
    top: 40px;
    background: #e9f0f2;
    /* border-radius: 0.5rem; */
    z-index: 10;
    border-bottom: 1px solid $gray-400;
    padding-bottom: 1rem;
}

.dark-theme {
    .sticky-formgroup {
        background-color: $kb-blue !important;
        border-color: #5b727a;
    }
}


.panel-button,
.modal-footer .btn {
    height: 39px;
    color: $white;
    font-size: 0.875rem;
}

.panel-button {
    width: 100%;
    transform: translateY(-2px);
}


.panel-button,
.panel-button:hover,
.panel-button:focus,
.panel-button:active,
.btn-info,
.btn-info:disabled,
.btn-info:hover,
.btn-secondary,
.btn-secondary:disabled,
.btn-secondary:hover,
.btn-success,
.btn-success:disabled,
.btn-success:hover,
.btn-success:active,
{
    color: $white;
}


.dark-theme {
    .value-evaluator {
        color: $white;
    }

    .popover-body .value-evaluator {
        color: $kb-blue !important;
    }
}


.default-loader {
    //pointer-events: none;
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1200;

    > * {
        border-radius: 2rem;
        background-color: rgba(255, 255, 255, .7);
        padding-left: 1rem;
        padding-right: 1rem;
        width: 260px;
        height: 130px;

        .card-title {
            width: 100%;
            height: 100%;
            padding: 1rem;
            padding-bottom: 0;
            padding-top: 1.25rem;

            .image-wrap {
                width: 100%;
                height: 100%;
                background: url("/assets/logo-dark.svg");
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
            }

        }

    }


}

.dark-theme {
    .default-loader {
        > * {
            background-color: rgba(255, 255, 255, .95);
            color: $kb-blue;
        }
    }
}


emoji-mart .emoji-mart {
    background-color: transparent !important;

    .emoji-mart-category-label span {
        //background: transparent;
    }
}

.dark-theme {
    emoji-mart .emoji-mart {
        color: #fff;
        border-color: rgba(255, 255, 255, .2);

        .emoji-mart-category-label span {
            background-color: #1d2931;
            border-bottom: 1px solid rgba(255, 255, 255, .2);
        }
    }


    option:hover {
        background-color: #0f2d37;
    }

    select option,
    select {
        color: white !important;
        background: #27424b !important;
    }
}


::ng-deep .popover {
    border-radius: 1rem;

    .popover-body {
        //padding: 0.25rem;
    }
}


.page-loading-indicator {
    pointer-events: none;
    position: fixed;
    z-index: 50;
    bottom: -2.5rem;
    left: 0.26rem;

    background: $kb-blue;
    color: $white;
    padding: .25rem;
    padding-left: .75rem;
    padding-right: .75rem;
    border-radius: .35rem;
    pointer-events: none;
    transition: .5s ease-out all;
    font-size: 75%;
    opacity: .25;
    transition-delay: .25s;


    &.active {
        opacity: 1;
        bottom: 2.5rem;
    }

}


.items-container:has(.reset-panel-buttons) {
    padding-bottom: 40px;
    min-width: 280px;

    .reset-panel-buttons {
        border-top: 1px solid $gray-500;
        height: 47px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-radius: 0 0 .65rem .65rem;
        background: $kb-gray-300;
        padding: .5rem;
        gap: .25rem;
        align-items: center;
        justify-content: end;

        .btn {
            height: 100%;
        }
    }
}


emoji-mart {

    .emoji-mart-dark .emoji-mart-search input {
        background-color: rgba(255, 255, 255, .1) !important;
        border-color: #adb5bd !important;
        margin-bottom: .2rem;
    }

    .emoji-mart-dark .emoji-mart-bar,
    .emoji-mart-dark {
        border-color: #adb5bd !important;
    }

    .emoji-mart-dark .emoji-mart-category-label span {
        color: #1a3b46;
        background: transparent !important;

    }
}

.dark-theme {
    .items-container:has(.reset-panel-buttons) .reset-panel-buttons {
        border-color: #233a42;
        background: #1a3b46;
    }

    emoji-mart {
        .emoji-badge {
            .badge {
                color: $white;
            }
        }

        .emoji-mart-dark .emoji-mart-bar,
        .emoji-mart-dark {
            border-color: #233a42 !important;
        }

        .emoji-mart-dark .emoji-mart-category-label span {
            color: #fff;
            background: transparent !important;

        }
    }
}


.menu-list {
    display: flex;
    gap: .25rem;
    flex-direction: column;

    :hover app-toggle-item {

    }

    li {
        border-color: $border-light-white;
    }

    li:has(hr) {
        height: .25rem;
        background-color: $kb-dusty;
        border: none;
        margin-top: -5px;

        hr {
            display: none;
        }

        &:after {
            display: none !important;;
        }
    }

    app-toggle-item {
        border-radius: .25rem !important;
        //transition: all .25s ease-out;
    }

    li:not(:last-child) {
        flex-wrap: wrap;

        &:after {
            height: .25rem;
            content: "";
            display: block;
            width: 100%;
            border-bottom: 1px solid $border-light-white;
        }
    }
}

.app-toggle-item {
    border-radius: .25rem;
    border: none;
}


:not(.dark-theme) {
    .list-unstyled.menu-list {
        li {
            border-color: $kb-gray-800;
        }

        li:hover .app-toggle-item,
        li:hover app-toggle-item {
            //background-color: $border-light-black;
            //background-color: $white;
            background-color: $kb-blue;
            color: $white;

            i:before {
                font-weight: bold;
                color: $white !important;
            }
        }

        li:not(:last-child) {
            flex-wrap: wrap;

            &:after {
                border-bottom: 1px solid $kb-gray-800;
            }
        }
    }
}

.dark-theme .list-unstyled.menu-list li:not(:last-child):after {
    border-color: $border-light-white;
}

.dark-theme .list-unstyled.menu-list li:has(hr) {
    background-color: $header-color;
}

app-dashboard-header app-card-appointment > div{
    border: 1px solid $kb-dark-gray-100;
    border-radius: .5rem;
}


/* https://podio.com/klartboard/softwareudvikling/apps/stories/items/1664  */
body:has([data-isCapturingScreenshot="true"]){
    height: fit-content!important;
    overflow: visible!important;
    width: fit-content!important;

    [data-isCapturingScreenshot="true"] {
        min-width: 100vw;
    }

    mat-sidenav-container{
        overflow-x: visible!important;
        width: fit-content!important;
    }

    .main-wrapper{
        height: unset;
    }

    app-display-dashboard {
        .project-wrap,
        ngx-datatable{
            overflow: visible!important;
            height: unset;
            margin-bottom: 0;
        }
        .ngx-datatable.bootstrap{
            contain: none!important;
        }
    }
    app-display-dashboard,
    app-display-kanban,
    app-display-kanban-progress,
    app-display-category {
        width: fit-content;
        > div{
            width: fit-content;
            overflow: visible;
        }

        .scroll-x:has(.phase-container) {
            width: fit-content!important;

            overflow: visible !important;
            //flex-wrap: wrap;
            height: fit-content !important;
            //flex-direction: row !important;

            .phase-container {
                //width: 100%;

                //border: 1px solid red;
                //height: fit-content!important;
            }

            footer {
                display: none;
            }
        }
    }

    #dataTable{
        overflow: visible!important;
        position: relative!important;
        min-height: 50vh;
        display: contents;

        > *{
            height: fit-content;
        }

        datatable-selection{
            height: fit-content;
        }
    }
    .page-container{
        height: fit-content;
        .mat-drawer-container{
            height: fit-content!important;
        }
    }
    .content-container{
        display: block;
        min-height: 100vh;
        width: 100%;
        overflow: visible;
        height: unset;

        .datatable-body {
            overflow: visible!important;
            height: unset!important;
        }

    }

}

@media print{

    .main-wrapper{
        height: unset;
    }

    app-display-kanban,
    app-display-kanban-progress,
    app-display-category {

        .scroll-x:has(.phase-container) {

            overflow: visible !important;
            flex-wrap: wrap;
            height: fit-content !important;
            flex-direction: row !important;

            .phase-container {
                width: 100%;
                //border: 1px solid red;
                height: fit-content!important;
            }

            footer {
                display: none;
            }
        }
    }
    body{
        height: fit-content!important;
        overflow: visible!important;
        .datatable-scroll{
            padding-bottom: 200px;
        }

        #dataTable{
            overflow: visible!important;
            position: relative!important;
            min-height: 50vh;
            display: contents;
            padding-bottom: 40vh;
            > *{
                height: fit-content;
            }

            datatable-selection{
                height: fit-content;
            }
        }
        .page-container{
            height: fit-content;
            .mat-drawer-container{
                height: fit-content!important;
            }
        }
        .content-container{
            display: block;
            min-height: 100vh;
            width: 100%;
            overflow: visible;
            height: unset;

            .datatable-body {
                overflow: visible!important;
                height: unset!important;
            }

        }

    }
}


// https://podio.com/klartboard/softwareudvikling/apps/stories/items/1664

[data-listsexpanded="true"] {
    .wrapper {
        .edit-content {
            height: auto!important;
            white-space: normal;
            max-height: 100%!important;
        }
    }
}

 body[card-show-status="false"],
 body[card-show-title="false"],
 body[card-show-details="false"],
 body[card-show-phase="false"],
 body[card-show-micro_cards="false"] {
     .card,
     .cdk-drop-list{
         min-height: unset!important;
         height: unset!important;


     }

     .title > .text-capitalize-firstletter{
         height: unset!important;
         //border: 1px solid red!important;
     }
 }


 body[card-pagination-enabled="false"] {
     .list-counter{
         display: none!important;
     }
 }
body[card-show-actions="false"] {
     .action-panel{
         display: none!important;
     }
    .footer-padding {
        padding-bottom: 0!important;
    }
 }
 body[card-show-status="false"] {
     .card {
         .card-type {
             display: none !important;
         }
         .title{
             height: unset!important;
             min-height: unset;
             vertical-align: middle;
             align-self: start;
         }
     }
 }

body[card-show-details="false"] {
    .card {
        .card-details > div:first-child{
            display: none!important;
        }

    }
}

body[card-show-title="false"] {
    .card {
        .title{
            display: none!important;
        }

    }
}
body[card-show-phase="false"] {
    .card {
        ul.phase{
            display: none!important;
        }
    }
}

body[card-show-micro_cards="false"] {
    .card {
        .card{
            display: none!important;
        }
    }
}

.position-sorter{
    position: absolute;
    top: .5em;
    right:.5em;
    z-index: 1;
    border-radius: .25em;
    background-color: rgba($kb-gray-300, .5);
    padding: .5em;
    opacity: .5;
    transition: opacity .25s ease-out;
    &:hover{
        opacity: 1;
    }
}

.ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
